// configs
import config from 'src/config';
import configCategory from 'src/ui/modules/Core/config/category.config';
// utils
import DataService from 'src/utils/DataService';
// constants
import { recordType } from 'ui/modules/Core/constants/category.constant';

export function getCategoryService(withParams: boolean = true) {
	const params = withParams ? `?${config.records[recordType].urlParams}` : '';
	return new DataService({
		url: `${config.records[recordType].url}${params}`,
		urlParams: '',
		token: true,
	});
}

export function getCategoryServiceByCode(code: string) {
	return new DataService({
		url: `${config.records[recordType].url}/${code}?${config.records[recordType].urlOneDatasetParams}`,
		urlParams: '',
	});
}

/**
 * bulk delete category
 * @param payload
 * @returns
 */
export function bulkDeleteCategoryService(payload: any) {
	return new DataService({
		url: `${configCategory.url}/delete`,
		urlParams: '',
	}).create(payload);
}
