import {
	faShip,
	faCogs,
	faChartArea,
	faChartNetwork,
	faCog,
	faScroll,
	faDatabase,
	faSwatchbook,
	faSitemap,
} from '@fortawesome/pro-light-svg-icons';
import { INavItemProps } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
import config from 'src/config';
import { filter, sortBy } from 'lodash';
import { getApiPrefix, version } from 'src/utils/api-prefix.helper';

const isProd = getApiPrefix() === version;

/**
 * mapping children configuration
 */
const mappingChildrenConfig = [
	{
		key: 'global-reference-mapping',
		description: 'pages.sidenav.mapping.global_reference_mapping',
		path: config.records.dataset_global_mapping.path,
		icon: config.records.dataset_global_mapping.iconName,
		is_child: true,
		permissionRecord: ['mapping'],
	},
	{
		key: 'dataset-reference-mapping',
		description: 'Dataset reference mapping',
		path: config.records.dataset_field_mapping.path,
		icon: config.records.dataset_field_mapping.iconName,
		is_child: true,
		permissionRecord: ['mapping'],
	},
];

/**
 * storage children configuration
 */
const storageChildrenConfig = [
	{
		key: 'email',
		description: 'model.email.name_plural',
		path: config.records.email.path,
		icon: config.records.email.iconName,
		is_child: true,
		permissionRecord: ['email'],
	},
	{
		key: 'email_ruleset',
		description: 'model.email_ruleset.name_plural',
		path: config.records.email_ruleset.path,
		icon: faCogs,
		is_child: true,
		permissionRecord: ['email_ruleset'],
	},
	{
		key: 'file',
		description: 'model.files.name_plural',
		path: config.records.files.path,
		icon: config.records.files.iconName,
		is_child: true,
		permissionRecord: ['file'],
		disabled: isProd,
	},
];

/**
 * setting children configuration
 */
const settingChildrenConfig = [
	{
		key: 'categories',
		description: 'model.category.name_plural',
		path: config.records.category.path,
		icon: config.records.category.iconName,
		is_child: true,
		permissionRecord: ['category'],
	},
	{
		key: 'label',
		description: 'modules.core.label.name_plural',
		path: config.records.label.path,
		icon: config.records.label.iconName,
		is_child: true,
		permissionRecord: ['label'],
	},
	{
		key: 'system_entity',
		description: 'model.system_entity.name_plural',
		path: config.records.system_entity.path,
		icon: config.records.system_entity.iconName,
		is_child: true,
		permissionRecord: ['system_entity'],
	},
	{
		key: 'users',
		description: 'pages.user.title_plural',
		path: config.records.user.path,
		icon: config.records.user.iconName,
		is_child: true,
		permissionRecord: ['user'],
	},
	{
		key: 'organization',
		description: 'modules.data_management.models.organization.title',
		path: '/organization',
		icon: faSitemap,
		is_child: true,
		permissionRecord: ['organization'],
	},
	{
		key: 'doc-v1',
		description: 'API documentation',
		path: '/documentation/api/v1',
		icon: faScroll,
		is_child: true,
		permissionRecord: ['api'],
	},
];

const referenceDataChildrenConfig = filter(
	// Orders the entries alphabetically
	sortBy(Object.entries(config.records), (it) => it[0]),
	// Gets only reference data
	(item: any) => item[1].referenceData === true
).map((item: any, index: number) => ({
	key: 'reference_data_' + index,
	path: item[1].path,
	icon: item[1].iconName ? item[1].iconName : null,
	composed_icon: item[1].icon ? item[1].icon : null,
	description: item[1].pageTitle.list,
	is_child: true,
	permissionRecord: ['reference'],
}));

/**
 * sidebar configuration
 */
export const sidebarConfig: INavItemProps[] = [
	{
		key: 'dashboard',
		description: 'model.dashboard.name_plural',
		path: '/dashboard',
		icon: faChartArea,
		module: ['dashboard'],
		permissionRecord: ['dashboard'],
	},
	{
		key: 'tradeflow',
		description: 'pages.tradeflow.title',
		path: '/fundamentals/tradematrix',
		icon: faShip,
		module: ['tradeflows'],
		permissionRecord: ['trade_flow'],
	},
	{
		key: 'dataset',
		description: 'model.dataset.name_plural',
		path: config.records.dataset.path,
		icon: config.records.dataset.iconName,
		module: ['datasets'],
		permissionRecord: ['dataset'],
	},
	{
		key: 'ingest-workflows',
		description: 'model.workflow.name_plural',
		path: config.records.workflow.path,
		module: ['woto'],
		permissionRecord: ['workflow'],
	},
	{
		key: 'transform-workflows',
		description: 'model.transform_workflow.name_plural',
		path: config.records.transform_workflow.path,
		icon: config.records.transform_workflow.iconName,
		module: ['woto'],
		permissionRecord: ['transform_workflow'],
	},
	{
		key: 'mapping',
		description: 'pages.sidenav.mapping.title',
		icon: faChartNetwork,
		children: mappingChildrenConfig,
		module: ['woto'],
		permissionRecord: ['mapping'],
	},
	{
		key: 'storage',
		description: 'pages.sidenav.storage',
		icon: faDatabase,
		children: storageChildrenConfig,
		module: ['storage'],
		permissionRecord: ['file', 'email', 'email_ruleset'],
	},
	{
		key: 'freight',
		description: config.records.freight.description,
		icon: config.records.freight.iconName,
		path: config.records.freight.path,
		permissionRecord: ['freight'],
		module: ['freight'],
	},
	// {
	// 	key: "report",
	// 	description: "pages.sidenav.report",
	// 	icon: config.records.report.iconName,
	// 	path: config.records.report.path,
	// 	permissionRecord: ["report"],
	// 	module: ["report"],
	// },
	{
		key: 'reference_data',
		description: 'pages.sidenav.reference_data',
		icon: faSwatchbook,
		path: config.records.reference_data.path,
		children: referenceDataChildrenConfig || [],
		permissionRecord: ['reference'],
	},

	{
		key: 'settings',
		description: 'pages.organization_settings.title',
		icon: faCog,
		children: settingChildrenConfig,
		permissionRecord: ['group', 'user', 'organization', 'api'],
	},
];
