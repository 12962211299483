export default {
	success: {
		tradeflow_refresh_completed: 'TradeMatrix has been successfully refreshed',
		refresh_completed: 'TradeMatrix has been successfully refreshed',
		update_completed: 'Your forecast has been correctly updated',
		archive_completed: 'Your forecast has been correctly published',
		create_completed: 'Your forecast has been correctly created',
		delete_completed: 'The resource has been deleted',
	},
	info: {
		aggregating_datasets: 'Aggregating datasets',
		warn_user_switch_tf_type: 'Are you sure ? This action will reset the settings saved for Reporter & Partners.',
		preparing_tradeflow: 'Transforming data for tradeMatrix',
		preparing_data: 'Transforming data for tradeMatrix',
		exporting_data: 'Exporting data',
		number_of_line_uniq: 'Your tradeMatrix selection is currently giving {{ number }} records',
		number_of_line_multiple:
			'Your tradeMatrix selection is currently giving between {{ number }} and {{ number_max }} records',
		number_of_line: {
			optimal: 'this number is optimal, your tradeMatrix should be fluid',
			semi_optimal: 'your tradeMatrix may encounter some slowness',
			non_optimal: 'your tradeMatrix may be slow',
			over_the_limit:
				'this number is not optimal. You should decrease the number of partner, reporter, product or define a closer view start date',
		},
	},
	error: {
		tradeflow_refresh_failed:
			'The tradeMatrix encountered problems when it was updated. Please check or update your priority rules',
		refresh_failed: 'TradeMatrix encountered some errors during refresh',
		something_went_wrong: 'Something went wrong',
		update_failed: 'A problem has occurred when updating your forecast',
		no_data:
			'No data was found for tradeMatrix. Please check your configuration.  This may be due to a configuration problem, please check the view start date, the period and the priority rules',
	},
	pCode: 'Product',
	oCode: 'Origin',
	dCode: 'Destination',
	dGroup: 'Destination group',
	Year: 'Year',
	CropYear: 'Crop year',
	oGroup: 'Origin group',
	Month: 'Month',
	filters: 'Predefined filters for tradeMatrix data extraction',
	groups: 'Define on which elements the details will be kept',
	has_row: 'Define whether or not you want to retrieve the ROW data, grouping all unconfigured partners',
};
