import React from 'react';
// Ant design
import { Button } from 'antd';
import { faCogs } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utils
import { useUserContext } from 'src/libs/contextLib';

const EmailRulesetButton = ({ history, translate }) => {
	const { myPermissions } = useUserContext() as any;
	if (myPermissions?.email_ruleset?.can_read) {
		return (
			<Button
				onClick={() => {
					history.push('/email-ruleset');
				}}
				type="primary"
			>
				<FontAwesomeIcon icon={faCogs} transform="left-3" /> {translate('model.email_ruleset.name')}
			</Button>
		);
	}
	return null;
};

export default EmailRulesetButton;
