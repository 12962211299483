export default {
	tabs: {},
	sections: {},
	fields: {
		contractee: {
			label: 'Contractee',
			placeholder: 'Select a contractee',
			required: 'Please input a contractee',
		},
		period: {
			label: 'Period',
			placeholder: 'Select a period',
			required: 'Please input a period',
		},
		resource_groups: {
			label: 'Resource Group',
			placeholder: 'Select a resource group',
			required: 'Please input resource group',
			empty: 'No resource group',
		},
		name: {
			label: 'Name',
			placeholder: 'Enter a name',
		},
		type: {
			label: 'Type',
			placeholder: 'Select a type',
			options: {
				dashboard: 'Dashboard',
				dataset: 'Dataset',
				forecast: 'Forecast',
				trade_flow: 'TradeMatrix',
			},
		},
		resources: {
			label: 'Resources',
			placeholder: 'Select a resource',
		},
		category: {
			label: 'Category',
			placeholder: 'Select a category',
		},
		shareable: {
			label: 'Shareable',
			state_on: 'Yes',
			state_off: 'No',
			placeholder: 'shareable',
		},
		amend_from: {
			label: 'amend from',

			placeholder: 'shareable',
		},
		description: {
			label: 'Description',
			placeholder: 'Enter a description',
		},
	},
	infos: {},
};
