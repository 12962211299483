export default {
	publish_version: {
		title: 'Start a new forecast version',
		message:
			"You are about to start a new forecast version. The old version won't be editable anymore. Are you sure?",
	},
	name_version: {
		title: 'Name your version',
		message: 'You can define an alias for your new version. Alternatively you can also leave it blank.',
	},
	create_edit_period: {
		title: 'Define the period',
		message: 'You can leave the fields empty to create a period with no bounds',
	},
	add_dataset: {
		title: 'Add a dataset',
	},
	add_tradeflow: {
		title: 'Add a tradeMatrix',
	},
	add_forecast: {
		title: 'Add a forecast',
	},
};
