export const recordType = 'dashboard';

export const types = [
	{ label: 'Iframe', value: 'iframe' },
	{ label: 'Power BI report', value: 'power_bi_report' },
];

export const validateMessages = {
	required: 'This field is required',
};

export const refreshTokenInterval = 1000 * 60 * 10; // 10 minutes

export const allBusinessCategories = {
	id: '*',
	code: '*',
	name: 'All dashboards',
	type: 'business_category',
};
