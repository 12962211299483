export const recordType = 'workflow';

export const getStatusColor = (status: string) => {
	if (status === 'success') return 'green';
	if (status === 'error') return 'red';
	if (status === 'warning') return 'yellow';
	return 'grey';
};

export const andGroupType = ['location', 'product', 'company'];

export const steps = [
	{
		id: 'loading',
		title: 'Extract',
	},
	{
		id: 'transforming',
		title: 'Transform',
	},
	{
		id: 'namemapping',
		title: 'Map columns',
	},
	{
		id: 'valuemapping',
		title: 'Map values',
	},
	{
		id: 'filtering',
		title: 'Filter',
	},
	{
		id: 'exporting',
		title: 'Load',
	},
];

export const errorListScript = [
	'handle_method_missing',
	'file_conversion_failed',
	'invalid_file',
	'script_execution_failed',
	'invalid_result',
];

export const stepsStatusOptions = [
	{ dirty: true, checked: true, checking: false, error: false },
	{ dirty: true, checked: false, checking: false, error: false },
	{ dirty: false, checked: false, checking: false, error: false },
	{ dirty: true, checked: false, checking: false, error: false },
	{ dirty: true, checked: false, checking: false, error: false },
	{ dirty: false, checked: false, checking: false, error: false },
];
export const workflow_state = {
	stopped: 'red',
	standby: 'yellow',
	runnable: 'green',
};
