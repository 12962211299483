export default {
	name: 'System Entity',
	name_plural: 'System Entities',
	new_record_title: 'Create a new System Entity',
	labels: {
		name: 'Name',
		type: 'Type',
		code: 'Code',
		private: 'Private',
		created_at: 'Created at',
		updated_at: 'Update at',
		created_by: 'Created by',
		updated_by: 'Update by',
	},
	actions: {
		create_new: 'Create a new Entity',
		created: 'Entity Created',
		updated: 'Entity Updated',
		save: 'Save',
		cancel: 'Cancel',
		add_type: 'Add New Type',
		type_name: 'Write Type Name',
	},
	errors: {
		access_denied: 'Access denied',
		error_occured: 'An error occured when retrieving system entities',
		not_applied: 'An error occured when applying tags to resources',
		error_get_entity: 'An error has occured when getting entity detail',
		error_create_entity: 'An error has occured when creating the entity',
		error_update_entity: 'An error has occured when updating the entity',
	},
	warnings: {
		error_update_entity_type: 'You are not allowed to create system entities with the type business category',
		contact_support: 'Contact Support to add new business category',
	},
	success: {
		created: 'Entity created',
		updated: 'Entity updated',
		applied: 'Selected records have been successfully updated.',
	},
};
