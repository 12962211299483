export default {
	name: 'Label',
	name_plural: 'Labels',
	new_record_title: 'New Label',
	messages: {
		error_get_label: 'An error has occured when getting label detail',
		error_create_label: 'An error has occured when creating the label',
		error_update_label: 'An error has occured when updating the label',
		success_create_label: 'has been created successfully',
		success_update_label: 'has been updated successfully',
		private_on_create: 'Labels are private by default, you can make them public after creation.',
	},
};
