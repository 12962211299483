export default {
	global: {
		copyright: 'Copyright',
		dataset_copyrigt: 'Dataset copyright',
		from_datasets: 'From datasets',
		from_tradeflows: 'From tradeMatrix',
		no_origin: 'No origin found, please provide origins via tradeMatrix configuration',
		no_conf_required: 'No configuration required for an aggregation tradeMatrix',
		lCode: 'Location',
		pCode: 'Product',
		CropYear: 'Crop year',
		filters: {
			lCode: 'All locations',
			pCode: 'All products',
			CropYear: 'All cropyears',
		},
	},
	forecast: {
		name: 'Forecast',
		name_plural: 'Forecasts',
		new_record_title: 'New forecast',
		actions: {
			create_new: 'Create a new forecast',
		},
		no_x: 'No forecast',
	},
	trade_flow: {
		name: 'tradeMatrix',
		name_plural: 'TradeMatrix',
		new_record_title: 'New tradeMatrix',
		actions: {
			create_new: 'Create a new tradeMatrix',
		},
		no_x: 'No tradeMatrix',
	},
	production: {
		name: 'Production',
		name_plural: 'Productions',
		new_record_title: 'New production',
		actions: {
			create_new: 'Create a new production',
		},
		no_x: 'No production',
	},
};
