export default {
	tabs: {
		general: 'General',
		settings: 'Settings',
		sources: 'Sources',
		rules: 'Rules',
		scheduling: 'Scheduling',
		scheduling_and_triggers: 'Scheduling and triggers',
		notifications: 'Notifications',
		sharing: 'Sharing',
		flowcharts: 'Flowcharts',
		preparation: 'Preparation',
		triggers: 'Triggers',
	},
	sections: {
		base_settings: 'Base settings',
		notifications: 'Notifications',
		deliveries: 'Define your deliveries',
	},
	fields: {
		include_forecasts: 'Include forecasts',
		port_detection_radius: 'Port detection radius',
		advanced: 'Flowcharts',
		sql: 'SQL',
		simple: 'Basic',
		basic: 'Basic',
		source_type: 'Source type',
		deliveries: {
			add_new: 'Add a new delivery',
			email_copy: 'Email a full copy of the dataset to other recipients',
			dropbox: 'Deliver a copy to Dropbox',
			info_dropbox: 'The path should reference an existing folder in your dropbox account',
			dropbox_folder: 'Dropbox folder/',
			source_placeholder: 'Define the different sources you wish to aggregate for export',
			no_delivery: 'No deliveries',
			push_dataset: 'Push the outcome to a dataset',
		},
		merge_field: {
			add_new: 'Add new columns',
			join_kind: 'Join kind',
			join_order: 'Chose left dataset',
			infos: {
				left_outer:
					'Left outer join: Keep all rows from the left dataset, and add the columns from the right dataset if the join condition is met',
				right_outer:
					'Right outer join: Keep all rows from the right dataset, and add the columns from the left dataset if the join condition is met',
				full: 'Full outer join: Keep all rows from both datasets, and add the columns from the other dataset if the join condition is met',
				inner: 'Inner join: Keep only rows from both datasets if the join condition is met',
				right_anti: 'Right anti join: Keep only rows from the right dataset if the join condition is not met',
				left_anti: 'Left anti join: Keep only rows from the left dataset if the join condition is not met',
				cross: 'Cross join: Keep all rows from both datasets, and add the columns from the other dataset',
			},
			field: {
				left_outer: 'Left outer join',
				right_outer: 'Right outer join',
				full: 'Full outer join',
				inner: 'Inner join',
				right_anti: 'Right anti join',
				left_anti: 'Left anti join',
				cross: 'Cross join',
			},
		},
		condition_field: {
			add_new: 'Add new operator',
		},
		empty: 'Empty',
		conditions: {
			add_new: 'Add a new condition',
			eq: 'Equal',
			neq: 'Does not equal',
			null: 'Is empty',
			notnull: 'Is not empty',

			gt: 'Greater than',
			gte: 'Greater or equal than',
			lt: 'Lower than',
			lte: 'Lower or equal than',
			btw: 'Is between range',
			outiside: 'Is outside range',
			below95p: 'Below 95th percentile',
			above95p: 'Above 95th percentile',
			below99p: 'Below 99th percentile',
			above99p: 'Above 999th percentile',
			empty: 'Empty',
		},
		replace_fields: {
			add_new: 'Add a new replace field',
		},
		replace_field: {
			add_new: 'Add a new replace field',
			new_column_name: 'New column name',
			new_column_name_tooltip: 'Fill this field if you want to apply the result in a new column name',
		},
		sort: {
			add_new: 'Add a new sort',
		},
		set_values: {
			add_new: 'Add a new set value',
			substr: 'Substring',
			regex: 'Regex match',
			word: 'Word match',
			start: 'Start',
			end: 'End',
		},
	},
	infos: {
		port_detection_radius: 'Port detection radius',
		advanced: 'Use pipeline advanced mode',
		sql: 'Use your own SQL query to define the dataprep',
		simple: 'Simple execution with a basic filter',
		basic: `The fields present for the filter in a "basic" view represent the intersection of the different fields available between the selected sources.
		If you want to apply filters on all fields for the sources separately, use the "advanced" version`,
		available_fields: 'Available fields',
		available_fields_for_filters: 'Available fields for filters',
		create_filter_to_search_into_sources: 'Create a filter to search into sources',
		available_sources: 'Available sources',
		no_sources: 'No sources, please add one to continue',
	},
	source_type: {
		datasets: 'Datasets',
		trade_flows: 'TradeMatrix',
		forecasts: 'Forecasts',
		production: 'Production',
		dataprep: 'Data prep',
	},
	buttons: {
		new_operation: 'New Operation',
	},
	label: {
		choose_mode: 'Choose a preparation mode',
	},
};
