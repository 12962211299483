import React from 'react';
import { createRoot } from 'react-dom/client'; // React 18 update
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import '/modules/i18n/i18n';
import './index.sass';
// import { ConfigProvider } from "antd";
// import en_US from "antd/lib/locale/en_US";

// Initialize Sentry in production mode
if (import.meta.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://32b9133eb8694bfd973e723c18704067@o23207.ingest.sentry.io/5723153',
		integrations: [new Integrations.BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.05,
	});
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Router>
		<App />
	</Router>
);
