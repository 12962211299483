// Shared utils
import _ from 'lodash';

// Shared Components
import SimpleFilters from 'ui/components/List/Filters/SimpleFilters';

export const filterParams = {
	filterOptions: [
		'contains',
		'notContains',
		'equals',
		'notEqual',
		SimpleFilters.isEmpty,
		SimpleFilters.isNotEmpty,
		'startsWith',
		'endsWith',
	],
	buttons: ['reset'],
	textFormatter: (r: any) => {
		if (_.isNil(r) || _.isEmpty(r)) return null;
		if (_.isArray(r)) return r;
		return r
			.toLowerCase()
			.replace(/[àáâãäå]/g, 'a')
			.replace(/æ/g, 'ae')
			.replace(/ç/g, 'c')
			.replace(/[èéêë]/g, 'e')
			.replace(/[ìíîï]/g, 'i')
			.replace(/ñ/g, 'n')
			.replace(/[òóôõö]/g, 'o')
			.replace(/œ/g, 'oe')
			.replace(/[ùúûü]/g, 'u')
			.replace(/[ýÿ]/g, 'y');
	},
	textCustomComparator: (filter: any, cellValue: any, filterText: any) => {
		if (_.isNil(cellValue)) return false;
		const filterTextLoweCase = filterText.toLowerCase();
		const newCellValue = _.isArray(cellValue) ? _.join(cellValue, ',') : cellValue;
		const valueLowerCase = newCellValue.toString().toLowerCase().replaceAll('-', '');
		switch (filter) {
			case 'contains':
				const filterTextLoweCases = filterTextLoweCase.split(' ');
				let check = true;
				for (let i = 0; i < filterTextLoweCases.length; i++) {
					if (!filterTextLoweCases[i]) continue;
					filterTextLoweCases[i] = filterTextLoweCases[i].replaceAll('-', '');

					check = check && valueLowerCase.includes(filterTextLoweCases[i].toLowerCase());
				}
				return check;
			case 'notContains':
				return valueLowerCase.indexOf(filterTextLoweCase) === -1;
			case 'equals':
				return valueLowerCase === filterTextLoweCase;
			case 'notEqual':
				return valueLowerCase !== filterTextLoweCase;
			case 'startsWith':
				return valueLowerCase.indexOf(filterTextLoweCase) === 0;
			case 'endsWith':
				var index = valueLowerCase.lastIndexOf(filterTextLoweCase);
				return index >= 0 && index === valueLowerCase.length - filterTextLoweCase.length;
			default:
				// should never happen
				return false;
		}
	},
	debounceMs: 200,
};

// Style cell AG-GRID
export const cellStyle = () => ({
	display: 'flex',
	alignItems: 'center',
	whiteSpace: 'normal',
	overflowWrap: 'break-word',
	lineHeight: '1.2',
	overflow: 'hidden',
});

export const defaultColDef = {
	floatingFilter: true,
	filter: 'agTextColumnFilter',
	filterParams,
	resizable: true,
	sortable: true,
	width: 100,
};

export const defaultSimpleColDef = {
	resizable: true,
};

export const centerCellStyle = {
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
	alignItems: 'center',
	height: '100%',
};

export const endCellStyle = {
	display: 'flex',
	justifyContent: 'end',
	textAlign: 'end',
	alignItems: 'center',
	height: '100%',
};

export const startCellStyle = {
	display: 'flex',
	justifyContent: 'start',
	textAlign: 'start',
	alignItems: 'center',
	height: '100%',
};

// Grid Options AG-GRID
export const gridOptions = {
	domLayout: 'autoHeight',
};

export const sideBar = {
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			toolPanelParams: {
				suppressRowGroups: true,
				suppressValues: true,
				suppressPivotMode: true,
			},
		},
		{
			id: 'filters',
			labelKey: 'filters',
			labelDefault: 'Filters',
			iconKey: 'menu',
			toolPanel: 'agFiltersToolPanel',
		},
	],
	defaultToolPanel: 'columns',
	hiddenByDefault: true,
};
