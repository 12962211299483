export default {
	fields: {
		summary: 'Summary',
		zoom: 'Zoom',
		sources: 'Sources',
		totalRows: 'Total rows',
		autosynch: 'Autosynch',
	},
	general: 'General settings',
	conversion: 'Conversion settings',
	decimals: 'Decimals settings',
	global: {
		no_period: 'No period configuration found',
	},
	trade_flow: {
		title: 'TradeMatrix priorities',
		description: 'Select and organize datasets that will compose your tradeMatrix',
	},
	ruleset: {
		item: 'Datasets, TradeMatrix & Estimates',
		title_estimates: 'Datasets & Estimates',
		products: 'Products',
		product_required: 'Atleast one product is required',
		used: 'SND',
		reference: 'Reference',
		unused: 'Available',
		drop_here: 'Drop items here',
		items: {
			dataset: 'Dataset',
			trade_flow: 'TradeMatrix',
			forecast: 'Forecast',
		},
	},
};
