export default {
	tabs: {
		general: 'General',
		settings: 'Settings',
		sharing: 'Sharing',
		forecasts: 'Forecasts',
		scheduling: 'Scheduling',
		notifications: 'Notifications',
		reporters_and_partners: 'Reporters & Partners',
		content: 'Content',
		intraflows: 'Intraflows',
		unreported: 'Unreported',
	},
	sections: {
		base_settings: 'Base settings',
		view_settings: 'View settings',
		notifications: 'Notifications',
		type: 'Type',
		infos: 'Infos',
		date_range: 'Date range',
	},
	fields: {
		aggregated: 'Aggregated',
		per_port: 'Per port',
		use_ports: 'Use ports',
		forecastable: 'Forecastable',
		display_row: 'Display ROW',
		filters: 'Filters',
		groups: 'Aggregated by',
		period: 'Period',
		min_date: 'Min date',
		max_date: 'Max date',
		products: 'Products',
		tradeflows: 'TradeMatrix',
		use_has_config: 'Use this tradeflow config',
		reporters: 'Reporters',
		partners: 'Partners',
		products: 'Products',
		tradeflows: 'TradeMatrix',
		content: 'Content',
		view_date: 'View date',
		snd_items: 'Snd items',
		configuration: 'Configuration',
		unreported: 'Unreported',
	},
	infos: {
		period: 'YEAR/MONTH',
		use_ports: 'Use ports',
		forecasts: 'Automatically loaded forecasts (Optional)',
		filters: 'Predefined filters for tradeflow data extraction',
		groups: 'Define on which elements the details will be kept',
		display_row: 'Define whether or not you want to retrieve the ROW data, grouping all unconfigured partners',
		select_source_info: 'Select a source to retrieve more info',
		no_info:
			"No information for this source. It may happen if the dataset has never run or you don't have the rights to read the data",
		more_info: 'more info',
		unreported: 'Unreported',
	},
	message: {
		date_lower: 'The date provided has to be greather than the start date',
		date_greater: 'The date provided has to be lower than the end date',
	},
	drawer: {
		stats: 'Stats',
		list_origin: 'List origins',
		select_origin: 'Select an origin to retrieve its stats',
	},
};
