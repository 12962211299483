export default {
	title: 'Comparison view',
	settings: {
		description: 'Please choose below the snapshots you would like to compare',
		select_snapshot_x: 'Select snapshot {{number}}',
		operations: {
			minus: 'minus',
		},
	},
	current: 'Current',
	current_tradeflow_version: 'Current tradeMatrix version',
	my_snapshots: 'My snapshots',
	from_organization: 'From my organization',
};
