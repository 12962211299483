import React, { Component, lazy } from 'react';
import { withTranslation } from 'react-i18next';
import { _ } from 'ag-grid-community';
// ant design & icons
import { TreeSelect, Spin, Input, Typography, Button, Divider, Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faFolder, faSyncAlt } from '@fortawesome/pro-light-svg-icons';
// components
import ReferenceField from '../ReferenceField/ReferenceField';
import userContext from 'src/libs/contextLib';
// utils
import { sortBy, memoize, isEqual, uniqBy, map } from 'lodash';
// config
import config from 'config';
// services
import DataService from 'src/utils/DataService';
import {
	contextOrAPIGetReference,
	hierarchyByPrefixReferenceSelect,
} from 'ui/modules/References/utils/reference.helper';
// sass & css
import './ReferenceSelect.sass';

const ReferenceDynamicForm = lazy(() => import('src/ui/modules/References/components/ReferenceDynamicForm'));
const CategoryForm = lazy(() => import('ui/modules/Core/components/Categories/CategoryForm/CategoryForm'));

const formComponents = {
	company: ReferenceDynamicForm,
	company_group: ReferenceDynamicForm,
	currency: ReferenceDynamicForm,
	custom_code: ReferenceDynamicForm,
	data_type: ReferenceDynamicForm,
	delivery_term: ReferenceDynamicForm,
	exchange: ReferenceDynamicForm,
	exchange_month_reference: ReferenceDynamicForm,
	location: ReferenceDynamicForm,
	location_group: ReferenceDynamicForm,
	period_aggregation_type: ReferenceDynamicForm,
	vegetation_index_variable: ReferenceDynamicForm,
	cost_production_item: ReferenceDynamicForm,
	price_type: ReferenceDynamicForm,
	product: ReferenceDynamicForm,
	product_group: ReferenceDynamicForm,
	quote_type: ReferenceDynamicForm,
	snd_item: ReferenceDynamicForm,
	trade_flow_mode: ReferenceDynamicForm,
	transport_category: ReferenceDynamicForm,
	transport_type: ReferenceDynamicForm,
	unit: ReferenceDynamicForm,
	vessel_status: ReferenceDynamicForm,
	product_category: ReferenceDynamicForm,
	source: ReferenceDynamicForm,
	category: CategoryForm,
};

const { Text } = Typography;
const indexedIds = {};

const UrlSearch = 'https://api.dnext.io/search/';

const convertData = memoize((data, byPrefix = false) => {
	if (!data?.[0]) data = [];

	if (byPrefix) {
		return hierarchyByPrefixReferenceSelect(data);
	} else {
		data.forEach((element) => {
			indexedIds[element.id] = element;
		});

		let results = data.map((e) => {
			const grandParent = [];
			const parent = indexedIds[e.pId];
			e.parentNb = e.parentNb || 0;
			e.order = `${e.label} ${e.text}`;
			if (parent) {
				e.label = `${parent.text.replace(/ *\([^)]*\) */g, '')} / `; // +e.text;

				// checkGrandparent
				grandParent[0] = indexedIds[parent.pId];
				for (let i = 0; i < 10; i++) {
					if (grandParent[i]) {
						e.parentNb += 1;
						e.label = `${grandParent[i].text.replace(/ *\([^)]*\) */g, '')} / ${e.label}`;
						grandParent[i + 1] = indexedIds[grandParent[i].pId];
					} else break;
				}

				e.order = `${e.label} ${e.text}`;

				e.label = (
					<>
						<span className="parent_element">{e.label}</span>
						{e.text}
					</>
				);
			}

			return e;
		});

		// if reference does't use is_group
		if (results[0]?.isGroup !== undefined) {
			results = results.map((d) => {
				if (d.isGroup && d.doneG !== true) {
					d.label = (
						<>
							<span style={{ marginRight: '5px' }}>
								<FontAwesomeIcon icon={faFolder} />
							</span>
							{d.label}
						</>
					);
					d.doneG = true;
				}

				return d;
			});
		}

		return sortBy(results, ['order']);
	}
});

class ReferenceSelect extends Component {
	static contextType = userContext;

	constructor(props) {
		super(props);

		this.state = {
			allData: props.data || [],
			options: props.data ? convertData(props.data) : [],
			value: undefined,
			loadedData: false,
			isSuggestedValue: props.isSuggestedValue,
			ready: false,
			fetching: false,
			drawerVisible: false,
			searchTerm: '',
			loaded: false,
			defaultOption: props.data ? convertData(props.data) : [],
		};

		this.recordType = props.recordtype.toLowerCase() === 'data_source' ? 'source' : props.recordtype.toLowerCase();

		this.access = true;
		this.parentField = config.records[this.recordType]?.parentField;
		this.hideOpenReference = props.hideOpenReference;
		this.suggestedValues = props.suggestedValues || [];
		this.confidencescore = props.confidencescore;
		this.apiURL = config.records[this.recordType]?.url;
		this.defaultOrder = '&order=[["name", "ASC"]]';
		this.referenceURL = `${config.records[this.recordType]?.path}/`;

		this.optionLabelFunction =
			props.optionlabelfunction ||
			function (data) {
				let label = data.name;
				if (data.code) label += ` (${data.code})`;
				return label;
			};
		this.treeWidth = this.hideOpenReference || this.props.treeCheckable ? '100%' : 'calc(100% - 42px)';
		this.filterType = this.props.filtertype !== undefined ? this.props.filtertype : null;
	}

	componentDidMount() {
		const { context } = this;
		this.myPermissions = context.myPermissions || null;
		if (this.recordType && !this.myPermissions?.reference?.can_create) {
			this.access = false;
		}

		// We need to transform the options to mark on them the suggested values
		let options = [...this.state.options];

		if (this.filterType !== null && this.props.filtervalue !== null) {
			options = options.filter((el) => el.filter?.toLowerCase() === this.props.filtervalue.toLowerCase());
		}

		if (this.props.onlyClean) this.setState({ options });

		if (!this.props.getdatas) {
			if (options.length > 0 && this.suggestedValues.length > 0) {
				options = options.map((option) => {
					if (this.suggestedValues.includes(option.value)) {
						option.label = (
							<div>
								<div style={{ float: 'left' }}>
									{option.isGroup ? (
										<span style={{ marginRight: '5px' }}>
											<FontAwesomeIcon icon={faFolder} />
										</span>
									) : null}
									{option.text}
								</div>

								<div style={{ float: 'right' }}>
									<Text
										type={
											this.confidencescore
												? this.confidencescore === 1
													? 'success'
													: 'warning'
												: 'warning'
										}
									>
										{this.confidencescore
											? this.confidencescore === 1
												? '100% confidence'
												: `${(this.confidencescore * 100).toFixed(0)}%` + ` confidence`
											: 'Suggested'}
									</Text>
								</div>
							</div>
						);
						option.text += 'suggestion';
					} else if (option.isGroup) {
						option.label = (
							<div>
								<div>
									<span style={{ marginRight: '5px' }}>
										<FontAwesomeIcon icon={faFolder} />
									</span>
									{option.text}
								</div>
							</div>
						);
					}

					return option;
				});

				this.setState({ options, defaultOption: options, ready: true });
			} else if (this.props.value && !this.props.data) {
				// TODO: have to check the context here (it's not working)
				this.fetch();
			} else this.setState({ ready: true });
		} else this.setState({ ready: true });
	}
	handleKeyDown = (event) => {
		if ((event.ctrlKey && event.key === 'c') || (event.ctrlKey && event.key === 'v')) {
			event.stopPropagation();
		}
	};
	componentDidUpdate(previousProps, previousState) {
		if (!isEqual(previousProps.disabledItems, this.props.disabledItems)) {
			if ([...this.state.defaultOption].length > 0) {
				const data = [...this.state.defaultOption].map((item) => {
					if (this.props.disabledItems && this.props.disabledItems.indexOf(item.id) !== -1)
						item.disabled = true;
					else item.disabled = false;
					return item;
				});

				this.setState({
					options: data,
					fetching: false,
					ready: true,
					loadedData: true,
				});
			}
		}
		if (!isEqual(previousProps.data, this.props.data) && !this.props.getdatas) {
			this.setState({ options: this.sortOptions(convertData(this.props.data)) });
		}

		if (previousProps.filterselect !== this.props.filterselect) {
			if (this.props.filterselect[this.props.fieldname] !== null) {
				const data = [...this.state.defaultOption].filter(
					(el) =>
						el.filter?.toLowerCase() === this.props.filterselect[this.props.fieldname].toLowerCase() ||
						this.props.value === el.id
				);
				this.setState({
					options: data,
				});
			} else {
				this.setState({
					options: [...this.state.defaultOption],
				});
			}
		}
	}

	loadDataIfNeeded(e) {
		this.showScrollBarOnTop();

		if (this.state.options.length < 1 && this.props.getdatas) this.fillOptionsDatas();

		if (this.props.changeflys && !this.state.loadedData) {
			const options = this.props.data || [];
			const datas = convertData(
				this.props.getdatas(this.props.recordtype, this.suggestedValues, this.confidencescore) || []
			);

			const opts = [...datas, ...options];

			let filtered = [...opts];

			if (this.props.filterselect[this.props.fieldname]) {
				filtered = [...opts].filter(
					(el) =>
						el.filter?.toLowerCase() === this.props.filterselect[this.props.fieldname].toLowerCase() ||
						this.props.value === el.id
				);
			}

			this.setState({
				options: this.sortOptions(filtered),
				defaultOption: this.sortOptions(opts),
			});
		} else {
			if (this.state.loadedData || this.props.data) return;
			this.fetch();
		}
	}

	onPopUpCreate(item, keepVisible = false) {
		const newValue = {};
		const desirecode = this.props.desirecode || false;

		if (desirecode) {
			this.fetch();
		}

		let options = convertData([...this.state.options, item]);

		options = this.sortOptions(options);

		this.setState({
			options,
			defaultOption: options,
			drawerVisible: keepVisible,
		});

		const regexp = /(\D+)_(\d+)_(\D+)/;
		const result = this.props.id.match(regexp);

		if (result) {
			// Example result: ["dataset_field_mapping_147_destination_value", "dataset_field_mapping", "147", "destination_value"]
			const dataArray = this.props.form.current.getFieldValue(result[1]);

			dataArray[result[2]][result[3]] = item.id;
			newValue[result[1]] = dataArray;
		} else {
			newValue[this.props.id] = item.value;
		}

		if (desirecode) {
			if (this.props.form)
				this.props.form.current.setFieldsValue({ [this.props.id]: `${item.code}#${item.value}` });
		} else {
			if (this.props.changefly) {
				this.props.changefly(newValue);
			}
			this.props.form.current.setFieldsValue(newValue);
		}
	}

	async fetch(refresh) {
		const currConfig = config.records[this.recordType];
		this.setState({ fetching: true });

		const target = { url: this.apiURL, search: false };

		if (this.props.inlineFilters) {
			target.url = UrlSearch + this.recordType;
			target.search = typeof this.props.inlineFilters === 'object';
			query = this.props.inlineFilters;
		}

		let rawRes = [];
		// References case record type is a reference
		if (currConfig.referenceRecord) {
			rawRes = await contextOrAPIGetReference(this.recordType, this.props, !refresh);
		} else {
			const service = currConfig.getService
				? currConfig.getService()
				: new DataService({
						url: target.url,
						urlParams: `?exclude=[parent]&limit=10000${this.defaultOrder}`,
					});
			let result = { data: [] };
			if (!this.props?.isReferenceListLoaded(this.recordType) || refresh) {
				result = target.search ? await service.getAlls(this.props.inlineFilters) : await service.getAll();
				this.props.setReferenceListValues(this.recordType, result);
			} else {
				result.data = this.props.getReferenceListValues(this.recordType)?.[0];
			}
			rawRes = Array.isArray(result?.data?.result) ? result?.data?.result : result?.data;
		}
		this.setState({ allData: rawRes });
		let data = (Array.isArray(rawRes) ? rawRes : []).map((d) => {
			const label = this.optionLabelFunction(d);
			const desirecode = this.props.desirecode || false;
			const dataSetCreation = this.props.dataSetCreation || false;
			let filter = null;
			if (this.filterType !== null) {
				filter = d[this.filterType];
			}

			let datavalue = desirecode ? `${d.code}#${d.id}${dataSetCreation ? `#${d.name}` : ''}` : d.id;
			if (this.props.onlycode) {
				datavalue = d.code;
			}

			const item = {
				label,
				value: datavalue,
				key: datavalue,
				text: label,
				code: d.code,
				name: d.name,
				id: d.id,
				pId: d[this.parentField],
				isGroup: d.is_group,
				filter,
			};

			if (this.props.disabledItems && this.props.disabledItems.indexOf(d.id) !== -1) item.disabled = true;

			return item;
		});

		data = convertData(data, this.recordType === 'custom_code_not_linked');

		if (this.props.addnone) {
			data.unshift({
				label: 'None',
				value: 'none',
				key: 'none',
				text: 'None',
				id: 'none',
				pId: null,
				disabled: false,
			});
		}

		if (this.filterType !== null && this.props.filtervalue !== null) {
			data = data.filter((el) => el.filter?.toLowerCase() === this.props.filtervalue.toLowerCase());
		}
		data = this.sortOptions(data);

		this.setState({
			options: data,
			defaultOption: data,
			fetching: false,
			ready: true,
			loadedData: true,
		});
	}

	sortOptions(options) {
		return options.sort((x, y) => (x.isGroup === y.isGroup ? 0 : x.isGroup ? 1 : -1));
	}

	showScrollBarOnTop() {
		const element = document.querySelectorAll('.ant-select-tree-list-holder');
		if (element) {
			Array.from(element).forEach((el) => {
				el.scrollTop = 0;
			});
		}
	}

	onLoadData = (treeNode, event, a) =>
		new Promise((resolve) => {
			if (this.props.getdatas === undefined || this.state.options.length > 1) {
				resolve();
			} else {
				this.fillOptionsDatas(() => {
					resolve();
				});
			}
		});

	fillOptionsDatas = (callback = null) => {
		const options = this.props.data || [];
		const datas = convertData(
			this.props.getdatas(this.props.recordtype, this.suggestedValues, this.confidencescore) || []
		);

		let opts = uniqBy([...datas, ...options], 'value');

		if (!opts[0]) opts = [];

		let filtered = [...opts];

		if (this.props.filterselect[this.props.fieldname]) {
			filtered = [...opts].filter(
				(el) =>
					el.filter?.toLowerCase() === this.props.filterselect[this.props.fieldname].toLowerCase() ||
					this.props.value === el.id
			);
		}
		this.setState(
			{
				options: this.sortOptions(filtered),
				defaultOption: this.sortOptions(opts),
				loadedData: true,
			},
			() => callback
		);
	};

	getMissedDatas() {
		if (this.state.loadedData) return true;
		const options = this.props.data || [];
		const datas = convertData(
			this.props.getdatas(this.props.recordtype, this.suggestedValues, this.confidencescore) || []
		);
		// datas = datas.filter((x) => x.value === this.props.value);

		// let opts = [...datas, ...options];

		this.setState({
			// options: this.sortOptions(opts),
			options: this.sortOptions(datas),
			checkmissedFailed: datas.length == 0,
		});
	}

	getRecordId(value, currConfig, data) {
		if (value) {
			const id = value.includes('#') ? value.split('#')[1] : value;
			const { navigateByCode } = currConfig;
			if (navigateByCode) {
				const code = (data || []).find((el) => el.id === id)?.code;
				if (code) return code;
				return null;
			}
			return id;
		}
		return null;
	}

	OpenReferenceComponent() {
		const currConfig = config.records?.[this.recordType];
		if (!currConfig) return;
		const data = this.props.getdatas ? this.props.getdatas(this.props.recordtype, [], null) : this.state.allData;
		const recordField = this.getRecordId(this.props.value, currConfig, data);
		const matchingObject = (data || []).find((el) => el.code === recordField);

		if (matchingObject) {
			const { path, groupOf } = currConfig;

			const referenceURL = `${path}${!groupOf && matchingObject?.is_group ? '-group' : ''}/${recordField}`;

			return (
				<Button
					size={this.props.size || 'middle'}
					disabled={!this.props.value || !recordField}
					icon={<ReferenceField url={referenceURL} />}
					className="reference-decoration"
				/>
			);
		}
	}

	getOptionsPerDesiredCode = (options) => {
		if (!this.props.data) return options;

		const desirecode = this.props.desirecode || false;
		const onlycode = this.props.onlycode || false;

		return map(options || [], (el) => {
			let dataValue = desirecode ? `${el.code}#${el.id}` : el.id;
			if (onlycode) {
				dataValue = el.code;
			}
			return {
				...el,
				value: dataValue,
			};
		});
	};

	render() {
		const currConfigRecord = this.recordType?.replace(/_and_group|_not_linked/g, '');
		const { ready, fetching, searchTerm } = this.state;
		if (!ready || this.props.isLoadingPreloadedData) {
			return <Spin size="small" />;
		}
		const {
			optionlabelfunction,
			isSuggestedValue,
			suggestedValues,
			hideOpenReference,
			disabledItems,
			popUpCreation,
			tReady,
			t,
			style,
			treeCheckStrictlyStrategry,
			treeCheckable,
			pushNotification,
			isReferenceListLoaded,
			setReferenceListValues,
			getReferenceListValues,
			...other
		} = this.props;

		const normalizedTerm = searchTerm
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replace(/[.*+?^${}()|[\]\\]/g, '.')
			.split(' ');

		const searchRegexp = searchTerm ? normalizedTerm.map((v) => new RegExp(v, 'i')) : [];

		const showDrawer = popUpCreation === undefined ? true : popUpCreation;
		const showOpenReference = !hideOpenReference && !treeCheckable;

		// const opts = uniqBy(this.state.options, 'value');

		if (
			this.state.options.length > 0 &&
			this.props.value !== undefined &&
			this.state.options.filter((x) => x.value === this.props.value).length < 1 &&
			this.props.getdatas &&
			!this.state.checkmissedFailed
		) {
			this.getMissedDatas();
		}
		if (other.onlycode) delete other.onlycode;
		if (other.desirecode) delete other.desirecode;

		const TreeComponent = (
			<TreeSelect
				{...other}
				onKeyDown={this.handleKeyDown}
				treeCheckStrictly={
					treeCheckStrictlyStrategry ? treeCheckStrictlyStrategry && treeCheckable : true && treeCheckable
				}
				treeCheckable={treeCheckable}
				treeNodeLabelProp="label"
				treeDefaultExpandAll
				onKeyPress={(e) => {
					e.key === 'Enter' && e.preventDefault();
				}}
				showSearch
				className={
					(isSuggestedValue ? ' suggested-value' : '') +
					(isSuggestedValue && this.confidencescore
						? this.confidencescore === 1
							? ' confident-suggestion'
							: ''
						: '') +
					(this.props.className || '')
				}
				style={{ width: this.treeWidth, ...style }}
				allowClear={this.props.allowClear}
				dropdownMatchSelectWidth
				autoComplete="dontshow"
				placeholder={`Select a ${this.props.recordtype.replace(/_/g, ' ')}`}
				notFoundContent={fetching ? <Spin size="small" /> : null}
				treeData={this.getOptionsPerDesiredCode(this.state.options)}
				loadData={this.onLoadData.bind(this)}
				searchValue={this.state.searchTerm}
				onSearch={(value) => {
					this.showScrollBarOnTop();
					this.setState({ searchTerm: value });
				}}
				onSelect={(value, node) => {
					this.setState({ searchTerm: '' });
					if (this.props.isEditableCell) {
						this.props.onSelectEditableCell(node);
					}
				}}
				onFocus={this.loadDataIfNeeded.bind(this)}
				treeNodeFilterProp="text"
				filterTreeNode={(inputValue, treeNode) => {
					const t =
						treeNode && treeNode.order
							? treeNode.order.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
							: '';

					return searchRegexp.filter((v) => v.test(t)).length == searchRegexp.length;
				}}
				dropdownRender={
					showDrawer &&
					formComponents[currConfigRecord] &&
					((menu) => (
						<div>
							{fetching ? <Spin size="small" /> : menu}
							<Divider style={{ margin: '4px 0' }} />
							{this.access && (
								<Button
									type="link"
									style={{
										color: '#01bce8',
									}}
									icon={<FontAwesomeIcon icon={faPlus} className="mr-1" />}
									onClick={() =>
										this.setState({
											drawerVisible: true,
										})
									}
								>
									{this.props.t(`model.${currConfigRecord}.actions.create_new`)}
								</Button>
							)}
							<Button
								type="link"
								style={{
									color: '#01bce8',
								}}
								icon={<FontAwesomeIcon icon={faSyncAlt} className="mr-1" />}
								onClick={() => {
									this.fetch(true);
									if (this.props.changefly) this.props.changefly();
								}}
							>
								Refresh list
							</Button>
						</div>
					))
				}
			/>
		);

		const RecordForm = formComponents[currConfigRecord];

		const DrawerComponent = RecordForm && (
			<Drawer
				title={this.props.t(`model.${currConfigRecord}.actions.create_new`)}
				width={720}
				onClose={() => this.setState({ drawerVisible: false })}
				open={this.state.drawerVisible}
				bodyStyle={{ paddingBottom: 80 }}
			>
				<RecordForm
					isPopUp
					onPopUpCreate={this.onPopUpCreate.bind(this)}
					pushNotification={pushNotification || null}
					isReferenceListLoaded={isReferenceListLoaded || null}
					setReferenceListValues={setReferenceListValues || null}
					getReferenceListValues={getReferenceListValues || null}
					recordType={currConfigRecord}
				/>
			</Drawer>
		);

		if (showOpenReference) {
			return (
				<Input.Group className="d-flex" style={{ width: style?.width }}>
					{TreeComponent}
					{this.OpenReferenceComponent()}
					{showDrawer && DrawerComponent}
				</Input.Group>
			);
		}
		return (
			<>
				{TreeComponent}
				{showDrawer && DrawerComponent}
			</>
		);
	}
}
export default withTranslation()(ReferenceSelect);
export const MemoizedReferenceSelect = React.memo(ReferenceSelect);
