import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';

import './ReferenceField.sass';

export default class ReferenceField extends Component {
	render() {
		return this.props.url ? (
			<Tooltip title="Open record in a new tab">
				<Link to={this.props.url} target="_blank" className="referenceLink">
					{this.props.value} <FontAwesomeIcon icon={faSearch} className="mr-1" size="sm" />
				</Link>
			</Tooltip>
		) : (
			<span className="referenceLink">{this.props.value}</span>
		);
	}
}
