export default {
	global: {
		success_created: 'Your {{ entity }} has been successfully created',
		success_updated: 'Your {{ entity }} has been successfully udpated',
		generic_error: 'A problem occured. Please try again later',
		last_refresh_done: 'Last data refresh has been done {{ since }} on {{ date }}',
	},

	trade_flow: {
		success: {
			tradeflow_refresh_completed: 'TradeMatrix has been successfully refreshed',
			refresh_completed: 'TradeMatrix has been successfully refreshed',
			update_completed: 'Your forecast has been correctly updated',
			archive_completed: 'Your forecast has been correctly published',
			create_completed: 'Your forecast has been correctly created',
			delete_completed: 'The resource has been deleted',
		},
		info: {
			aggregating_datasets: 'Aggregating datasets',
			warn_user_switch_tf_type:
				'Are you sure ? This action will reset the settings saved for Reporter & Partners.',
			preparing_tradeflow: 'Transforming data for tradeMatrix',
			preparing_data: 'Transforming data for tradeMatrix',
			exporting_data: 'Exporting data',
			number_of_line_uniq: 'Your tradeMatrix selection is currently giving {{ number }} records',
			number_of_line_multiple:
				'Your tradeMatrix selection is currently giving between {{ number }} and {{ number_max }} records',
			number_of_line: {
				optimal: 'this number is optimal, your tradeMatrix should be fluid',
				semi_optimal: 'your tradeMatrix may encounter some slowness',
				non_optimal: 'your tradeMatrix may be slow',
				over_the_limit:
					'this number is not optimal. You should decrease the number of partner, reporter, product or define a closer view start date',
			},
		},
		error: {
			tradeflow_refresh_failed:
				'The tradeMatrix encountered problems when it was updated. Please check or update your priority rules',
			refresh_failed: 'TradeMatrix encountered some errors during refresh',
			something_went_wrong: 'Something went wrong',
			update_failed: 'A problem has occurred when updating your forecast',
			no_data:
				'No data was found for tradeMatrix. Please check your configuration.  This may be due to a configuration problem, please check the view start date, the period and the priority rules',
		},
		about_tradeflow: 'About this tradeMatrix',
		last_refresh_done: 'Last data refresh has been done {{ since }} on {{ date }}',
		no_editable_forecast: "No forecast found or the selected forecast can't be modified",
		editing_forecast_x: 'You are currently editing forecast {{ name }}',
		data_refreshed_successfully: 'Data successfully refreshed',
		refresh_started: 'Trade flow data refresh started',
		refresh_started_explanation: 'Please wait for the refresh to complete before accessing the trade flow view',
		bulk_update_successful:
			'The update of your forecast has been successfully sent. Please wait for your grid to update',
		update_period_dataset: 'New validation has been set successfully',
		please_refresh: 'Please refresh your data since new validation dates has been defined',
		warn: 'Warn me when is available',
		rows_validation:
			'Some updates exist for the following datasets since your last validation. You can review the list below and change the date that marks the end of finalized data.',
	},
};
