import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from '../libs/contextLib';
import config from 'src/config';
import NotAccess from 'ui/pages/NotAccess';
import Loader from 'ui/components/Loader/Loader';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import { canCreate, canUpdate } from 'src/utils/UserPermissions/UserPermissions.helper';
// constants
const edit = config.interface.editRecordUrlValue;
const create = config.interface.newRecordUrlValue;

const AuthRoute = ({
	children,
	path,
	exact,
	pageTitle,
	computedMatch: match,
	permissionRecord,
	authRoute,
	...rest
}: any) => {
	const { t: translate } = useTranslation();
	const { isAuthenticated, user, myPermissions, isLoadingPermission } = useUserContext() as any;
	const Component = rest.component;
	const routeProps = { exact, path, match };

	useEffect(() => {
		document.title = pageTitle ? `DNext - ${pageTitle}` : 'DNext';
	}, [pageTitle]);

	const getRedirectionRoute = () => {
		const redirection = localStorage.getItem(`${user.org}-redirection`);
		localStorage.removeItem(`${user.org}-redirection`);
		return redirection || '/';
	};

	if (isAuthenticated && isLoadingPermission) {
		return <Loader />;
	} else if (
		isAuthenticated &&
		(!myPermissions?.profile?.can_read ||
			(permissionRecord && path.includes(create) && !canCreate(myPermissions, permissionRecord)) ||
			(permissionRecord && path.includes(edit) && !canUpdate(myPermissions, permissionRecord)))
	) {
		return <NotAccess />;
	}

	if (typeof Component === 'undefined') {
		return (
			<Route {...rest} {...routeProps}>
				{(() => {
					if (
						(!isAuthenticated && !authRoute) ||
						(isAuthenticated && authRoute) ||
						(import.meta as any).env.VITE_REACT_APP_NEED_AUTH === 'false'
					)
						return children;
					if (isAuthenticated && !authRoute) {
						return <Redirect to={getRedirectionRoute()} />;
					}
					return <Redirect to="/login" />;
				})()}
			</Route>
		);
	}

	return (
		<Route
			render={(props) => {
				const { match, ...otherProps } = props; // Destructure match from props
				let renderedComponent;
				if (
					(!isAuthenticated && !authRoute) ||
					(isAuthenticated && authRoute) ||
					(import.meta as any).env.VITE_REACT_APP_NEED_AUTH === 'false'
				) {
					if (typeof children !== 'undefined') {
						renderedComponent = children;
					} else {
						renderedComponent = (
							<GlobalErrorBoundary {...otherProps} translate={translate}>
								<Component
									{...otherProps}
									{...rest}
									{...routeProps}
									permissionRecord={permissionRecord}
								/>
							</GlobalErrorBoundary>
						);
					}
				} else if (isAuthenticated && !authRoute) {
					renderedComponent = <Redirect to={getRedirectionRoute()} />;
				} else {
					renderedComponent = <Redirect to="/login" />;
				}

				return (
					<Route {...rest} {...routeProps}>
						{renderedComponent}
					</Route>
				);
			}}
		/>
	);
};

export default AuthRoute;
