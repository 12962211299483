export const server = 'https://api.dnext.io/';
export const basePath = 'dnext';
export const client = 'client';
export const version = 'v1.0';
export const beta = 'beta';
export const dev = 'dev';
export const data = 'data';
export const dataManagement = 'data-management';
export const ingestWorkflow = 'ingest-workflow';
export const referenceData = 'references';
export const fundamentals = 'fundamentals';
export const dataPrep = 'data-prep';

export const getApiPrefix = (onlyDev = false) => {
	if (onlyDev) return dev;
	if (
		document.location.hostname === 'localhost' ||
		document.location.hostname.includes('dndev') ||
		document.location.hostname.includes('stage')
	) {
		return dev;
	}
	if (document.location.hostname.includes('dnexr')) {
		return beta;
	}

	return version;
};
