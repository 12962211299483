export default {
	global: {
		copyright: 'Copyright',
		view: 'View',
		configuration: 'Configuration',
		search: 'Search',
		filter: 'Filter',
		download: 'Download',
		refresh_data: 'Refresh data',
	},
	title: {
		select_dataset: 'Select dataset',
		select_forecast: 'Select forecast',
	},
	tab: {
		extracontent: {
			legends: {
				title: 'Legends',
				reference: 'Reference',
				official: 'Official',
				estimate: 'Is available for forecast',
			},
		},
	},
	trade_flow: {},
	priority_rules: 'Prirority rules',
	configuration: 'Configuration',
	reset_changes: 'Reset changes',
	save_forecast: 'Save change in current version',
	publish_forecast: 'Publish version and start a new one',
	confirm_update: `Do you confirm this action ? Current forecast: {{ name }}`,
	editing_forecast_x: 'Editing forecast {{ name }}',
	no_editable_forecast: 'No editable forecast',
	filter_dataset: 'Filter dataset',
	search_dataset: 'Search dataset',
	search_forecast: 'Search forecast',
	cancel: 'Cancel',
	confirm: 'Confirm',
	add_forecast: 'Add forecast',
	add_dataset: 'Add dataset',
};
