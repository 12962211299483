import { DataService } from 'src/utils';
import { fundamentals, getApiPrefix, server } from 'src/utils/api-prefix.helper';

import { orderBy, uniqBy } from 'lodash';
import { getTaskStatus } from 'utils/getResource';
import { sleep } from 'utils/Validator';
import Axios from 'axios';
import { saveAs } from 'file-saver';

// To be remove after removing old sharing
import config from 'config';

export const getTradeflowService = () => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/tradeflows?old_sharing=true`,
		urlParams: '',
		token: true,
	});
};

// can be ID or code
export const deleteTradeflow = (id: string) => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/tradeflows`,
		urlParams: '',
		token: true,
	}).delete(id);
};

export const getOneTradeflowService = (code: string) => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/tradeflows/`,
		urlParams: '',
		token: true,
	}).getClean(code);
};

export const downloadTradeflow = async (
	code: string,
	refresh = false,
	intradeflow = false,
	row = false,
	format = 'csv',
	rest = {},
	aggregations = false
) => {
	try {
		let result = await new DataService({
			url: `${server}${getApiPrefix()}/${fundamentals}/tradeflows/${
				aggregations ? 'aggregations/' : ''
			}${code}/download`,
			urlParams: '',
			token: true,
		}).create({
			intraflows: {
				strategy: intradeflow ? 'include' : 'exclude',
			},
			row: row,
			refresh: !refresh,
			...rest,
			format: format,
		});

		const fileUrl = result.data.result.url;
		const task_id = result.data.task.id;
		let task = null;
		let counter = 0;

		while (!task && counter < 600) {
			await sleep(2000);
			let status = await getTaskStatus(fileUrl, task_id);
			if (status === 'SUCCEEDED') task = true;
			if (status === 'FAILED') throw 'error_trying_reaching_resource';
			counter += 5;
		}

		if (task) {
			const file = await Axios.get(fileUrl, {
				responseType: 'blob', // Important
			});
			const blob = file.data;

			saveAs(blob, `${code}.csv`);

			return true;
		}
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const getTradeflowDataByCode = async (code: string, refresh = false, start_date = null) => {
	try {
		const result = await new DataService({
			url: `${server}${getApiPrefix()}/${fundamentals}/tradeflows/${code}/get`,
			urlParams: '',
		}).create({
			useCache: refresh,
			intraflows: {
				strategy: 'include',
			},
			start_date: start_date,
		});

		const fileUrl = result.data.result?.result?.url;
		const task_id = result.data.result?.task?.id;
		const last_refreshed_at = result.data.result?.refreshed_at || new Date();
		if (!task_id) return { data: { url: result.data.result.url, task_id: null, refreshed_at: last_refreshed_at } };
		let task = null;
		let counter = 0;

		while (!task && counter < 600) {
			await sleep(2000);
			let status = await getTaskStatus(fileUrl, task_id);
			if (status === 'SUCCEEDED') task = true;
			if (status === 'FAILED') throw 'error_trying_reaching_resource';
			counter += 5;
		}

		if (task) {
			return {
				data: {
					url: fileUrl,
					task_id: task_id,
					refreshed_at: last_refreshed_at,
				},
			};
		}
	} catch (err) {
		console.log('err', err);
		return null;
	}
};
export const refreshTradeflowByCode = async (code: string) => {
	try {
		return await new DataService({
			url: `${server}${getApiPrefix()}/${fundamentals}/tradeflows/${code}/refresh`,
			urlParams: '',
		}).create({
			useCache: false,
		});
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const getListTradeflows = async (exclude = [], selectFormat = false) => {
	let data = [];
	const result = await getTradeflowService().getAll();
	data = result.data.result.filter((x: any) => ![exclude].includes(x.id));

	if (selectFormat) {
		data = data.map((el: any) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			parent: el.parent ? el.parent.name : null,
		}));
	}
	return orderBy(data, 'name', 'asc');
};

// To be remove after removing old sharing
export const getAvailableTradeflows = async (id: string, selectFormat = false) => {
	const result = await getTradeflowService().getAll();
	let data = result.data.result.filter((c: any) => c.id !== id);

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el: any) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			parent: el.parent ? el.parent.name : null,
		}));
	}

	return uniqBy(data.flat(), 'code');
};
